import { useMemo } from 'react'
import parse from 'wellknown'
import { feature } from '@turf/helpers'
import _ from 'lodash'

// constants
import { DETECTION_TYPES_TO_OMIT_ON_MAP } from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { DETECTION_TYPES } from 'app/MissionControlMethaneSolution/constants/common'

// types
import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'
import type { GeojsonData } from 'types/map'
import type { UtcISOString } from 'types/datetime'

type EnhancedDetectionData = DetectionData & {
  additionalData?: {
    contributingEmissions?: {
      startTime: UtcISOString
      endTime: UtcISOString
      totalVolume: number
      sourceName: string
      sourceLocation: string
    }
  }[]
}

const useDetectionsGeoJsonFeatures = (detections?: EnhancedDetectionData[]) =>
  useMemo(() => {
    return (
      detections?.reduce((acc: GeojsonData[], detection) => {
        const { geoJsonFeature, detectionType, additionalData } = detection

        if (DETECTION_TYPES_TO_OMIT_ON_MAP.includes(detectionType)) {
          return acc
        }

        if (detectionType === DETECTION_TYPES.DETECTION_TYPE_CMS) {
          const contributingEmissionsFeatures = _(
            additionalData?.contributingEmissions
          )
            .filter('sourceLocation')
            .map(emissions =>
              feature(parse(emissions?.sourceLocation), {
                ...emissions,
                ..._.pick(detection, ['priority', 'detectionType']),
              })
            )
            .value()
          if (!_.isEmpty(contributingEmissionsFeatures)) {
            acc.push(...contributingEmissionsFeatures)
          }
        } else if (geoJsonFeature) {
          acc.push(geoJsonFeature)
        }

        return acc
      }, []) ?? []
    )
  }, [detections])

export default useDetectionsGeoJsonFeatures
